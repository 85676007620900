
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import Notification from '@/mixins/Notification';
import StackedForm from '@/mixins/StackedForm';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import EditCampaignLoader from '@/components/campaign/EditCampaignLoader.vue';
import CampaignForm from '@/components/campaign/CampaignForm.vue';
import { Campaign as CampaignModel } from '@/interfaces/models/Campaign';
import { Venue } from '@/interfaces/models/Venue';
import { User } from '@/interfaces/models/User';
import { CampaignStatus } from '@/enums/CampaignStatus';
import { scrollToFailedField } from '@/util/helper';

const campaign = namespace('campaign');
const app = namespace('app');
const auth = namespace('auth');

@Component({
  components: { VWrapper, EditCampaignLoader, CampaignForm },
})
export default class EditCampaign extends mixins(Editable, Notification, StackedForm) {
  @campaign.Action('store') public store!: any;
  @campaign.Action('show') public show!: any;
  @campaign.Action('update') public update!: any;
  @campaign.State('active') public active!: CampaignModel;
  @app.State('venues') public venues!: Venue[];
  @auth.State('user') public user!: User;

  public $refs!: {
    form: InstanceType<typeof CampaignForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get campaign() {
    if (this.editing && this.active) {
      return this.active;
    }

    return null;
  }

  get editable() {
    const status = this.campaign?.status;
    return status !== CampaignStatus.FAILED && status !== CampaignStatus.SUCCESS && status !== CampaignStatus.ERROR;
  }

  get title() {
    return this.editing ? 'campaign.edit' : 'campaign.create';
  }

  public save() {
    if (!this.editable) {
      return;
    }

    this.$refs.form.validate().then(async (res: boolean | boolean[]) => {
      this.$startLoading('campaign.saving');
      if (this.isValid(res)) {
        if (this.editing) {
          await this.update({ ...this.$refs.form.getData(), id: this.campaign!._id });
        } else {
          await this.store(this.$refs.form.getData());
        }

        this.$router.push({ name: 'campaign.index' });
      } else {
        scrollToFailedField();

        this.notifyError('notification.form');
      }
      this.$stopLoading('campaign.saving');
    });
  }

  public async mounted() {
    if (this.editing) {
      this.$startLoading('campaign');
      await this.show({ id: this.id });
    }

    if (this.editing && !this.active) {
      this.$router.push({ name: 'campaign.index' });
    }

    this.$stopLoading('campaign');
  }
}
